import { graphql, Link as GatsbyLink } from "gatsby"
import React from "react"
import Page from "../components/page"
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import dotsSquareImg from "../img/dots-square.png"
import { colors } from "../theme"
import exclamationAnimation from "../animations/exclamation.json"
import lottie from "lottie-web"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  borderLeftRadius: {
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  borderRightRadius: {
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  borderBottomLeftRadius: {
    borderBottomLeftRadius: 24,
  },
  overflowHidden: {
    overflow: "hidden",
  },
}))

const SignUpCarrierNotVerified = () => {
  const classes = useStyles()
  const theme = useTheme()
  const animationContainerRef = React.useRef(null)

  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  React.useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current, // the dom element
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: exclamationAnimation, // the animation data
      })

      return () => {
        lottie.destroy()
      }
    }
  }, [])

  return (
    <Box py={8} mb={3} position="relative">
      {md && (
        <Box
          className={classes.borderLeftRadius}
          bgcolor={colors.grayBackground}
          position="absolute"
          height="100%"
          width={"40%"}
          top={0}
          right={0}
          zIndex={-1}
        />
      )}
      <Container maxWidth="md">
        <Box pb={9}>
          <Box position="relative">
            <Box
              position="absolute"
              height={190}
              width={190}
              zIndex={-1}
              top={-theme.spacing(3)}
              right={-theme.spacing(lg ? 5 : md ? 3 : 1)}
              style={{
                backgroundImage: `url(${dotsSquareImg})`,
                backgroundRepeat: "no-repeat",
              }}
            />
            {md && (
              <Box
                className={classes.borderBottomLeftRadius}
                bgcolor={colors.shipCars}
                position="absolute"
                height={228}
                width={254}
                zIndex={-1}
                bottom={-theme.spacing(3)}
                left={-theme.spacing(3)}
              />
            )}
            <Paper className={classes.overflowHidden} elevation={12}>
              <Box mx={6} my={12}>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6}>
                    <Box px={4} ref={animationContainerRef} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h3" gutterBottom color="textPrimary">
                      <span>Oops!</span>{" "}
                      <Box component="span" color="error.main">
                        Account not verified.
                      </Box>
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="textPrimary"
                    >
                      <span>
                        There was an issue with verifying your account
                        automatically. Please contact support at
                      </span>{" "}
                      <Link href="tel:+18445227744" noWrap>
                        (844) 522-7744
                      </Link>{" "}
                      <span>or</span>{" "}
                      <Link href="mailto:support@ship.cars" noWrap>
                        support@ship.cars
                      </Link>{" "}
                      <span>for assistance.</span>
                    </Typography>
                    <Box mt={6}>
                      <Box my={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          size="large"
                          href="tel:+18445227744"
                        >
                          Call Support
                        </Button>
                      </Box>
                      <Box my={1}>
                        <Button
                          size="large"
                          fullWidth
                          component={AdapterLink}
                          to="/sign-up-carrier"
                        >
                          Back to Sign Up
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const SignUpCarrierNotVerifiedPage = ({ data: { footerData } }) => {
  return (
    <Page headerData={{ frontmatter: {} }} footerData={footerData}>
      <SignUpCarrierNotVerified />
    </Page>
  )
}

export default SignUpCarrierNotVerifiedPage

export const pageQuery = graphql`
  query SignUpCarrierNotVerifiedQuery {
    ...PageFragment
  }
`
